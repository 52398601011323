<template>
  <section>
    <div class="content-header">
      <h2>“What does my headscarf mean to you?”</h2>
    </div>
    <div class="content-wrapper">
      <p>We tend to emphasize psychological barriers associated with career resilience as skills an individual, regardless of background, is capable of developing. This is all well and good, but systemic barriers such as racism and bias in hiring persist. These barriers disproportionately affect ethno-racial, immigrant and other marginalized groups. </p>
      <p>These barriers to labour market participation and career development weaken the potential for career resilience in members of these groups as a whole. At the same time, the resilience of individuals from marginalized groups allows them to navigate the systemic barriers they continue to face.</p>
      <p>In this <a
          href="https://www.ted.com/talks/yassmin_abdel_magied_what_does_my_headscarf_mean_to_you/transcript?utm_source=tedcomshare&utm_medium=email&utm_campaign=tedspread"
          target="_blank"
        >witty, honest and empathetic Ted Talk</a>, Yassmin Abdel-Magied explores the concept of unconscious bias by asking us to examine our first impressions of others. </p>
      <p>As you watch, take a moment to reflect on:</p>
      <ul>
        <li>The importance of challenging personal assumptions</li>
        <li>How to be aware of and look for your own biases</li>
        <li>How personal bias and systemic barriers influence workplace practices and career outcomes</li>
        <li>How awareness of unconscious bias is critical in creating a climate that fosters career resilience</li>
      </ul>
      <p>Note that Abdel-Magied cautions against connecting students only to mentors or role models who “look like them”. In fact, she encourages doing just the opposite, pairing mentees with mentors from different backgrounds. She suggests adopting this approach so that mentees can benefit from the social location of mentors and so that mentors can learn about issues and barriers faced by mentees who don’t share the same lived experience.</p>
      <p>It’s also important to respect a student’s agency in deciding on a mentor, recognizing that some students may prefer to work with a mentor who understands their lived experience.</p>
      <p>In your role, can you help connect students to those who can open doors for them and who can show them experiences and perspectives that they would normally not have access to?</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
